import { VFC } from "react"
import { Layout } from "../components/Layout"

const TagPage: VFC = () => {
  return (
    <Layout title='김춘식의 짱쎈 블로그'>
      TagPage
    </Layout>
  )
}

export default TagPage
